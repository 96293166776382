import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import {
  useGetLayoutQuery,
  useAddGalleryImageMutation,
  useDeleteGalleryImageMutation,
} from "../../redux/features/Admin/layouts/layoutApi";
import toast from "react-hot-toast";

const GallerySection = () => {
  const { data: layout, isLoading, refetch } = useGetLayoutQuery();
  const [addGalleryImage, { isLoading: isAdding }] =
    useAddGalleryImageMutation();
  const [deleteGalleryImage] = useDeleteGalleryImageMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const images = layout?.layout?.gallery?.images || [];

  const handleAddImage = () => {
    setOpenDialog(true);
  };

  const handleDeleteImageClick = (imageId) => {
    setImageToDelete(imageId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteImage = async () => {
    if (imageToDelete) {
      setIsDeleting(true);
      try {
        await deleteGalleryImage(imageToDelete).unwrap();
        refetch();
        toast.success("Image deleted successfully!");
      } catch (error) {
        console.error("Error deleting image:", error);
        toast.error("Failed to delete image.");
      } finally {
        setIsDeleting(false);
        setImageToDelete(null);
        setOpenDeleteDialog(false);
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDialogSubmit = async () => {
    if (!selectedFile) {
      toast.error("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      await addGalleryImage(formData).unwrap();
      refetch();
      toast.success("Image uploaded successfully!");
      handleDialogClose();
    } catch (error) {
      console.error("Error submitting image:", error);
      toast.error("Failed to upload image.");
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-800">Gallery</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddImage}
          className="bg-indigo-600 hover:bg-indigo-700"
        >
          Add New Image
        </Button>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <CircularProgress />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image) => (
            <div key={image._id} className="relative group">
              <img
                src={image.url}
                alt="Gallery"
                className="w-full h-48 object-cover rounded-lg shadow-md"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <button
                  onClick={() => handleDeleteImageClick(image._id)}
                  className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Add New Image</DialogTitle>
        <DialogContent>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="mt-4"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSubmit} disabled={isAdding}>
            {isAdding ? <CircularProgress size={24} /> : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this image?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleDeleteImage}
            color="error"
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GallerySection;
