import React from 'react';
import { useGetLayoutQuery } from '../redux/features/Admin/layouts/layoutApi';

function YearlyActivities() {
  const { data, isLoading, isError } = useGetLayoutQuery();

  if (isLoading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (isError) {
    return <div className="text-center py-10 text-red-500">Error loading activities</div>;
  }

  const activities = data?.layout?.yearlyActivities || [];

  const groupedActivities = activities.reduce((acc, activity) => {
    if (!acc[activity.month]) {
      acc[activity.month] = [];
    }
    acc[activity.month].push(activity);
    return acc;
  }, {});

  return (
    <div className="container mx-auto px-4 py-12 bg-gray-100">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800">Yearly Activities</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
        {Object.entries(groupedActivities).map(([month, monthActivities]) => (
          <div key={month} className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
            <div className="bg-gradient-to-r from-blue-600 to-blue-400 text-white py-3 px-6">
              <h3 className="text-xl md:text-2xl font-bold">{month}</h3>
            </div>
            <ul className="p-4 md:p-6 space-y-4">
              {monthActivities.map((activity, index) => (
                <li key={index} className="text-gray-700">
                  <h4 className="text-base md:text-lg font-semibold text-blue-700 mb-2">
                    {activity.eventTitle}
                  </h4>
                  <p className="text-sm leading-relaxed pl-4 border-l-2 border-blue-200">
                    {activity.content}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default YearlyActivities;