import React, { useState } from "react";
import { Close, Menu, PersonOutline } from "@mui/icons-material";
import ChurchLogo from "../../static/ChurchLogo.jpeg";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Box, Fade, Backdrop } from "@mui/material";
import Login from "../../components/Login";

const navItems = [
  { id: 1, text: "About", link: "#about" },
  { id: 2, text: "Mission", link: "#mission" },
  { id: 3, text: "Beliefs", link: "#beliefs" },
  { id: 4, text: "Activities", link: "#yearly-activities" },
  { id: 5, text: "Members", link: "#officials" },
  { id: 6, text: "Gallery", link: "#gallery" },
  { id: 7, text: "Location", link: "#location" },
];

const defaultAvatar = "https://res.cloudinary.com/dfg9h3dpx/image/upload/v1721717217/kyundong_global_church/gcd6vvnwkqdlugle0xpx.jpg";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleProfileClick = () => {
    if (user) {
      navigate('/dashboard');
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <img src={ChurchLogo} alt="Church Logo" className="h-12 w-auto mr-3" />
            <span className="text-xl font-semibold text-gray-800">Kyungdong Global Church</span>
          </div>
          <div className="hidden md:flex items-center space-x-6">
            {navItems.map((item) => (
              <a
                key={item.id}
                href={item.link}
                className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out"
                onClick={() => setNav(false)}
              >
                {item.text}
              </a>
            ))}
            <button
              onClick={handleProfileClick}
              className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-full text-sm font-medium transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {user ? (
                <img
                  src={user.avatar?.url || defaultAvatar}
                  alt="User Avatar"
                  className="h-10 w-10 rounded-full border-2 border-gray-200"
                />
              ) : (
                <PersonOutline style={{ fontSize: 28 }} />
              )}
            </button>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={handleNav}
              className="text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 p-2 rounded-md"
            >
              {nav ? <Close style={{ fontSize: 24 }} /> : <Menu style={{ fontSize: 24 }} />}
            </button>
          </div>
        </div>
      </div>
      {nav && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-gray-50">
            {navItems.map((item) => (
              <a
                key={item.id}
                href={item.link}
                className="text-gray-600 hover:text-gray-900 hover:bg-gray-100 block px-3 py-2 rounded-md text-base font-medium transition duration-150 ease-in-out"
                onClick={() => setNav(false)}
              >
                {item.text}
              </a>
            ))}
            <button
              onClick={handleProfileClick}
              className="text-gray-600 hover:text-gray-900 hover:bg-gray-100 block px-3 py-2 rounded-md text-base font-medium w-full text-left transition duration-150 ease-in-out"
            >
              {user ? "Profile" : "Login"}
            </button>
          </div>
        </div>
      )}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 400 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            outline: 'none',
          }}>
            <h2 className="text-2xl font-semibold text-center mb-6">Welcome Back</h2>
            <Login onClose={handleCloseModal} />
            <p className="mt-6 text-center text-gray-600">
              Don't have an account? <Link to="/register" className="text-blue-500 hover:text-blue-700 font-medium transition duration-150 ease-in-out" onClick={handleCloseModal}>Register</Link>
            </p>
          </Box>
        </Fade>
      </Modal>
    </nav>
  );
};

export default Navbar;
