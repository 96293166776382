import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userLoggedIn,userLoggedOut } from "../auth/authSlice";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const baseQuery = fetchBaseQuery({
  baseUrl: `${BACKEND_URL}/api/v1/`,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);
    if (refreshResult.data) {
      // store the new token
      api.dispatch(userLoggedIn({
        accessToken: refreshResult.data.accessToken,
        user: refreshResult.data.user,
      }));
      // retry the original query with new token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(userLoggedOut());
    }
  }
  return result;
};


export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    refreshToken: builder.mutation({
      query: (data) => ({
        url: "auth/refresh",
        method: "GET",
        credentials: "include",
      }),
     
    }),
    loadUser: builder.query({
      query: (data) => ({
        url: "users/me",
        method: "GET",
        credentials: "include",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            userLoggedIn({
              accessToken: result.data.accessToken,
              user: result.data.user,
            })
          );
        } catch (error) {
          console.log(error.message);
        }
      },
    }),
  }),
});

export const { useRefreshTokenMutation, useLoadUserQuery } = apiSlice;
