import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  user: "",
  tokenExpiration: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userRegistration: (state, action) => {
      state.token = action.payload.token;
    },
    userLoggedIn: (state, action) => {
      state.token = action.payload.accessToken;
      state.user = action.payload.user;
      // Set expiration to 15 minutes (in milliseconds)
      state.tokenExpiration = Date.now() + 15 * 60 * 1000;
    },
    userLoggedOut: (state) => {
      state.token = "";
      state.user = "";
      state.tokenExpiration = null;
    },
    userActivated: (state) => {
      state.isActivated = true;
    },
    updateUserAvatar: (state, action) => {
      if (state.user) {
        state.user.avatar = action.payload;
      }
    },
  },
});

export const {
  userRegistration,
  userLoggedIn,
  userLoggedOut,
  userActivated,
  updateUserAvatar
} = authSlice.actions;

export default authSlice.reducer;
