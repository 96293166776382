import { apiSlice } from "../../api/apiSlice";

export const layoutApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLayout: builder.query({
      query: () => "/layouts",
    }),
    updateHeroSection: builder.mutation({
      query: (heroSection) => ({
        url: "/layouts/hero-section",
        method: "PUT",
        body: heroSection,
      }),
    }),
    addYearlyActivity: builder.mutation({
      query: (activity) => ({
        url: "/layouts/yearly-activity",
        method: "POST",
        body: activity,
      }),
    }),
    updateYearlyActivity: builder.mutation({
      query: ({ activityId, ...activity }) => ({
        url: `/layouts/yearly-activity/${activityId}`,
        method: "PUT",
        body: activity,
      }),
    }),
    deleteYearlyActivity: builder.mutation({
      query: (activityId) => ({
        url: `/layouts/yearly-activity/${activityId}`,
        method: "DELETE",
      }),
    }),
    addYoutubeActivity: builder.mutation({
      query: (activity) => ({
        url: "/layouts/youtube-activity",
        method: "POST",
        body: activity,
      }),
    }),
    updateYoutubeActivity: builder.mutation({
      query: ({ activityId, ...activity }) => ({
        url: `/layouts/youtube-activity/${activityId}`,
        method: "PUT",
        body: activity,
      }),
    }),
    deleteYoutubeActivity: builder.mutation({
      query: (activityId) => ({
        url: `/layouts/youtube-activity/${activityId}`,
        method: "DELETE",
      }),
    }),
    addGalleryImage: builder.mutation({
      query: (imageData) => ({
        url: "/layouts/gallery-image",
        method: "POST",
        body: imageData,
      }),
    }),
    deleteGalleryImage: builder.mutation({
      query: (imageId) => ({
        url: `/layouts/gallery-image/${imageId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetLayoutQuery,
  useUpdateHeroSectionMutation,
  useAddYearlyActivityMutation,
  useUpdateYearlyActivityMutation,
  useDeleteYearlyActivityMutation,
  useAddYoutubeActivityMutation,
  useUpdateYoutubeActivityMutation,
  useDeleteYoutubeActivityMutation,
  useAddGalleryImageMutation,
  useDeleteGalleryImageMutation,
} = layoutApi;
