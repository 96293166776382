// import React, { useState, useEffect } from 'react';
// import { useUpdateAvatarMutation } from '../../redux/features/user/userApi';
// import { useDispatch } from 'react-redux';
// import { updateUserAvatar } from '../../redux/features/auth/authSlice';

// const ChangeAvatar = ({ currentAvatar }) => {
//   const dispatch = useDispatch();
//   const [previewUrl, setPreviewUrl] = useState(null);
//   const [file, setFile] = useState(null);
//   const [updateAvatar, { isLoading, isSuccess, isError, error, data }] = useUpdateAvatarMutation();

//   useEffect(() => {
//     if (isSuccess && data) {
//       dispatch(updateUserAvatar(data.user.avatar));
//     }
//   }, [isSuccess, data, dispatch]);

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     if (selectedFile) {
//       setFile(selectedFile);
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setPreviewUrl(reader.result);
//       };
//       reader.readAsDataURL(selectedFile);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!file) return;

//     const formData = new FormData();
//     formData.append('avatar', file);

//     await updateAvatar(formData);
//   };

//   return (
//     <div className="bg-white p-6 rounded-lg shadow-md">
//       <h2 className="text-2xl font-semibold mb-4">Change Avatar</h2>
//       <div className="flex items-center mb-4">
//         <img
//           src={previewUrl || currentAvatar || '/default-avatar.png'}
//           alt="Avatar Preview"
//           className="w-24 h-24 rounded-full object-cover mr-4"
//         />
//         <label className="bg-gray-200 text-gray-700 py-2 px-4 rounded cursor-pointer hover:bg-gray-300 transition duration-300">
//           Choose File
//           <input type="file" className="hidden" onChange={handleFileChange} accept="image/*" />
//         </label>
//       </div>
//       {file && (
//         <form onSubmit={handleSubmit}>
//           <button 
//             type="submit" 
//             className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300 disabled:bg-green-300"
//             disabled={isLoading}
//           >
//             {isLoading ? 'Updating...' : 'Update Avatar'}
//           </button>
//         </form>
//       )}
//       {isError && <p className="text-red-500 mt-2">{error?.data?.message || 'Failed to update avatar. Please try again.'}</p>}
//       {isSuccess && <p className="text-green-500 mt-2">Avatar updated successfully</p>}
//     </div>
//   );
// };

// export default ChangeAvatar;
import React, { useState, useEffect } from 'react';
import { useUpdateAvatarMutation } from '../../redux/features/user/userApi';
import { useDispatch } from 'react-redux';
import { updateUserAvatar } from '../../redux/features/auth/authSlice';

const ChangeAvatar = ({ currentAvatar }) => {
  const dispatch = useDispatch();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [updateAvatar, { isLoading, isSuccess, isError, error, data }] = useUpdateAvatarMutation();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(updateUserAvatar(data.user.avatar));
    }
  }, [isSuccess, data, dispatch]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;

    const formData = new FormData();
    formData.append('avatar', file);

    await updateAvatar(formData);
  };

  return (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4">Change Avatar</h2>
      <div className="flex flex-col sm:flex-row items-center mb-4">
        <img
          src={previewUrl || currentAvatar || '/default-avatar.png'}
          alt="Avatar Preview"
          className="w-24 h-24 rounded-full object-cover mb-4 sm:mb-0 sm:mr-4"
        />
        <label className="bg-gray-200 text-gray-700 py-2 px-4 rounded cursor-pointer hover:bg-gray-300 transition duration-300 text-sm sm:text-base">
          Choose File
          <input type="file" className="hidden" onChange={handleFileChange} accept="image/*" />
        </label>
      </div>
      {file && (
        <form onSubmit={handleSubmit}>
          <button 
            type="submit" 
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300 disabled:bg-green-300 text-sm sm:text-base"
            disabled={isLoading}
          >
            {isLoading ? 'Updating...' : 'Update Avatar'}
          </button>
        </form>
      )}
      {isError && <p className="text-red-500 mt-2 text-sm">{error?.data?.message || 'Failed to update avatar. Please try again.'}</p>}
      {isSuccess && <p className="text-green-500 mt-2 text-sm">Avatar updated successfully</p>}
    </div>
  );
};

export default ChangeAvatar;