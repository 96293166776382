import React from "react";

const UserProfile = ({ user }) => {
  return (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
      <h2 className="text-xl sm:text-2xl font-semibold mb-4">User Profile</h2>
      <div className="mb-4">
        <strong className="block text-gray-700 text-sm sm:text-base">
          Name:
        </strong>
        <span className="text-sm sm:text-base">{user.fullname}</span>
      </div>
      <div className="mb-4">
        <strong className="block text-gray-700 text-sm sm:text-base">
          Email:
        </strong>
        <span className="text-sm sm:text-base">{user.email}</span>
      </div>
      <div className="mb-4">
        <strong className="block text-gray-700 text-sm sm:text-base">
          Responsibility:
        </strong>
        <span className="text-sm sm:text-base">{user.responsibilities}</span>
      </div>
      <div className="mb-4">
        <strong className="block text-gray-700 text-sm sm:text-base">
          About:
        </strong>
        <p className="mt-2 text-gray-600 text-sm sm:text-base">
          {user.bio || "No bio provided"}
        </p>
      </div>
    </div>
  );
};

export default UserProfile;
