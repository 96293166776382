import React from "react";
import Navbar from "./Navbar";
import About from "./About";
import Mission from "./Mission";
import CoreBeliefs from "../CoreBeliefs";
import Activities from "../../Pages/Activities";
import YearlyActivities from "../../Pages/YearlyActivities";
import Officials from "../../Pages/Officials";
import Gallery from "../../components/Gallery";
import Location from "../Location";
import Footer from '../../components/Footer'

function Hero() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">
        <section id="About">
          <About />
        </section>
        <section id="mission">
          <Mission />
        </section>
        <section id="beliefs">
          <CoreBeliefs />
        </section>
        <section id="activities">
          <Activities />
        </section>
        <section id="yearly-activities">
          <YearlyActivities />
        </section>
        <section id="officials">
          <Officials />
        </section>
        <section id="gallery">
          <Gallery />
        </section>
        <section id="location">
          <Location />
        </section>
        <section>
          <Footer/>
        </section>
      </main>
    </div>
  );
}

export default Hero;
