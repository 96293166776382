import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRefreshTokenMutation } from "../redux/features/api/apiSlice";
import { userLoggedOut } from "../redux/features/auth/authSlice";

const TokenRefresher = () => {
  const dispatch = useDispatch();
  const { token, tokenExpiration } = useSelector((state) => state.auth);
  const [refreshToken] = useRefreshTokenMutation();

  useEffect(() => {
    if (token && tokenExpiration) {
      const refreshInterval = setInterval(async () => {
        const timeUntilExpiration = tokenExpiration - Date.now();
        if (timeUntilExpiration <= 5 * 60 * 1000) {
          // Refresh 5 minutes before expiration
          try {
            await refreshToken().unwrap();
          } catch (err) {
            console.error("Failed to refresh token:", err);
            dispatch(userLoggedOut());
          }
        }
      }, 1 * 60 * 1000); // Check every 1 minute

      return () => clearInterval(refreshInterval);
    }
  }, [token, tokenExpiration, refreshToken, dispatch]);

  return null;
};

export default TokenRefresher;
