import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useActivateUserMutation } from "../redux/features/auth/authApi";
import { motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";

const ActivateAccount = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [activateUser, { isLoading, isSuccess, isError, error, data }] =
    useActivateUserMutation();

  useEffect(() => {
    if (token) {
      activateUser(token);
    }
  }, [token, activateUser]);

  useEffect(() => {
    if (isSuccess) {
      if (data.success) {
        toast.success("Account activated successfully!");
        setTimeout(() => navigate("/login"), 5000);
      } else if (data.message === "User is already verified") {
        toast("Your account is already verified.", { icon: "🔔" });
        setTimeout(() => navigate("/login"), 5000);
      }
    }
    if (isError) {
      toast.error(
        error?.data?.message || "Activation failed. Please try again."
      );
    }
  }, [isSuccess, isError, navigate, data, error]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const contentVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.5, delay: 0.2 } },
  };

  return (
    <motion.div
      className="min-h-screen flex items-center justify-center bg-gray-100"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <Toaster />
      <motion.div
        className="bg-white rounded-lg p-8 shadow-md w-full max-w-md"
        variants={contentVariants}
      >
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">
          Account Activation
        </h2>

        {isLoading && (
          <div className="flex justify-center items-center">
            <motion.div
              className="w-12 h-12 border-4 border-blue-500 rounded-full border-t-transparent"
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            />
          </div>
        )}

        {isSuccess && data.success && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 200, damping: 10 }}
            className="bg-green-100 rounded-lg p-4 text-green-800"
          >
            <h3 className="text-xl font-semibold mb-2">
              Account Activated Successfully
            </h3>
            <p>Your account has been activated. Redirecting to login page...</p>
          </motion.div>
        )}

        {isSuccess &&
          !data.success &&
          data.message === "User is already verified" && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 10 }}
              className="bg-yellow-100 rounded-lg p-4 text-yellow-800"
            >
              <h3 className="text-xl font-semibold mb-2">Already Verified</h3>
              <p>Your account is already verified. You can log in directly.</p>
            </motion.div>
          )}

        {isError && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 200, damping: 10 }}
            className="bg-red-100 rounded-lg p-4 text-red-800"
          >
            <h3 className="text-xl font-semibold mb-2">Activation Failed</h3>
            <p>
              {error?.data?.message || "An error occurred. Please try again."}
            </p>
          </motion.div>
        )}

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="mt-6 w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
          onClick={() => navigate("/login")}
        >
          Go to Login
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default ActivateAccount;
