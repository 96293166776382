import React from 'react';
import { useGetLayoutQuery } from '../redux/features/Admin/layouts/layoutApi';

function Activities() {
  const { data: layoutData, isLoading, isError } = useGetLayoutQuery();

  if (isLoading) return <div className="flex justify-center items-center h-64">Loading...</div>;
  if (isError) return <div className="flex justify-center items-center h-64">Error loading activities</div>;

  const activitiesData = layoutData?.layout?.youtubeActivities || [];

  return (
    <div className="bg-gray-100 py-12 sm:py-16 lg:py-24">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl sm:text-4xl font-bold text-center text-gray-800 mb-8 sm:mb-12">Church Activities On Youtube</h1>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {activitiesData.map((activity) => (
            <div key={activity._id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <div className="aspect-w-16 aspect-h-9">
                <iframe 
                  src={`https://www.youtube.com/embed/${activity.youtubeLink.split('v=')[1]}`} 
                  title={activity.title.englishTitle}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen
                  className="w-full h-full"
                ></iframe>
              </div>
              <div className="p-4">
                <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">{activity.title.englishTitle}</h2>
                <h3 className="text-base sm:text-lg text-gray-700 mb-2">{activity.title.koreanTitle}</h3>
                <p className="text-sm text-gray-600">{new Date(activity.date).toLocaleDateString()}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Activities;