import React from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import {
  HomeOutlined as HomeIcon,
  PeopleOutlined as UserGroupIcon,
  CalendarTodayOutlined as CalendarIcon,
  PhotoOutlined as PhotographIcon,
  SettingsOutlined as CogIcon,
  ExitToAppOutlined as LogoutIcon,
  ArrowBackOutlined as ArrowLeftIcon,
  CloseOutlined as CloseIcon,
} from "@mui/icons-material";

const Sidebar = ({ closeSidebar }) => {
  return (
    <ProSidebar className="h-screen md:h-auto">
      <div className="md:hidden p-4">
        <button onClick={closeSidebar} className="text-gray-500">
          <CloseIcon />
        </button>
      </div>
      <Menu iconShape="round">
        <MenuItem icon={<HomeIcon />} onClick={closeSidebar}>
          <Link to="/admin/dashboard">Dashboard</Link>
        </MenuItem>
        <MenuItem icon={<UserGroupIcon />} onClick={closeSidebar}>
          <Link to="/admin/users">Users</Link>
        </MenuItem>
        <MenuItem icon={<CalendarIcon />} onClick={closeSidebar}>
          <Link to="/admin/church-activities">Church Activities</Link>
        </MenuItem>
        <MenuItem icon={<CalendarIcon />} onClick={closeSidebar}>
          <Link to="/admin/yearly-activities">Yearly Activities</Link>
        </MenuItem>
        <MenuItem icon={<PhotographIcon />} onClick={closeSidebar}>
          <Link to="/admin/gallery">Gallery</Link>
        </MenuItem>
        <MenuItem icon={<CogIcon />} onClick={closeSidebar}>
          <Link to="/admin/customization">Customization</Link>
        </MenuItem>
        <MenuItem icon={<ArrowLeftIcon />} onClick={closeSidebar}>
          <Link to="/">Back to Homepage</Link>
        </MenuItem>
        <MenuItem icon={<LogoutIcon />} onClick={closeSidebar}>
          <Link to="/admin/logout">Logout</Link>
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;