import React from "react";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";

function Footer() {
  return (
    <footer className="bg-gray-800 text-white text-center items-center py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">천진중앙교회 교육관</h3>
            <p className="mb-2">강원도 고성군 토성면</p>
            <p className="mb-2"> 천진7길 12</p>
            <p>Phone: 070-7786-9192</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="#about"
                  className="hover:text-blue-300 transition duration-300"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#yearly-activities"
                  className="hover:text-blue-300 transition duration-300"
                >
                  Activities
                </a>
              </li>
              <li>
                <a
                  href="#officials"
                  className="hover:text-blue-300 transition duration-300"
                >
                  Members
                </a>
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-center flex-col">
            <h3 className="text-xl font-semibold mb-4">Connect With Us</h3>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/people/Kyungdong-Global-Church/61562474413865/?mibextid=LQQJ4d&rdid=uSTHvDAGnnOfEECZ&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FgaGPgvFCvDTtzfjo%2F%3Fmibextid%3DLQQJ4d"
                className="text-white hover:text-blue-300 transition duration-300"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/kgckorea"
                className="text-white hover:text-blue-300 transition duration-300"
              >
                <Instagram />
              </a>
              <a
                href="https://www.youtube.com/@icwc6019/videos"
                className="text-white hover:text-blue-300 transition duration-300"
              >
                <YouTube />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p>
            &copy; {new Date().getFullYear()} Kyungdong Global Church. All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
