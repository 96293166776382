import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userLoggedOut } from "../../redux/features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useLogOutMutation } from "../../redux/features/auth/authApi";
import ChangeAvatar from "./ChangeAvatar";
import ChangePassword from "./ChangePassword";
import UserProfile from "./UserProfile";

const UserDashboard = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [logOut] = useLogOutMutation();

  const handleLogout = async () => {
    try {
      await logOut().unwrap();
      dispatch(userLoggedOut());
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleAdminDashboard = () => {
    navigate("/admin/dashboard");
  };
  const handleBackToHomepage = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <div className="bg-indigo-600 p-4 sm:p-6 rounded-t-lg flex flex-col sm:flex-row justify-between items-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-white mb-4 sm:mb-0">
            Welcome, {user.fullname}
          </h1>
          <div className="flex flex-wrap justify-center sm:justify-end items-center gap-2 sm:gap-4">
            <button
              onClick={handleBackToHomepage}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 text-sm sm:text-base"
            >
              Back to Homepage
            </button>
            {user.role === "admin" && (
              <button
                onClick={handleAdminDashboard}
                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300 text-sm sm:text-base"
              >
                Admin Dashboard
              </button>
            )}
            <button
              onClick={() => setShowLogoutModal(true)}
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300 text-sm sm:text-base"
            >
              Logout
            </button>
          </div>
        </div>

        <div className="bg-white p-4 sm:p-6 rounded-b-lg shadow-md">
          <div className="grid grid-cols-1 gap-6">
            <UserProfile user={user} />
            <ChangeAvatar currentAvatar={user.avatar?.url} />
            <ChangePassword />
          </div>
        </div>
      </div>

      {/* Logout Confirmation Modal */}
      {showLogoutModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center px-4">
          <div className="relative p-5 border w-full max-w-sm sm:max-w-md shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Logout Confirmation
              </h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500">
                  Are you sure you want to logout? You will be returned to the
                  homepage.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full sm:w-auto sm:mr-2 mb-2 sm:mb-0 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={handleLogout}
                >
                  Logout
                </button>
                <button
                  className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full sm:w-auto hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  onClick={() => setShowLogoutModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
