import { apiSlice } from "../../api/apiSlice";

export const analyticsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardAnalytics: builder.query({
      query: () => ({
        url: "analytics/dashboard",
        method: "GET",
        credentials: "include",
      }),
    }),
  }),
});

export const {
  useGetDashboardAnalyticsQuery,
} = analyticsApi;