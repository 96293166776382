import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Chip,
  CircularProgress,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";

import { Edit, Delete, CheckCircle } from "@mui/icons-material";
import {
  useGetAllUsersAdminQuery,
  useDeleteUserByAdminMutation,
  useUpdateUserByAdminMutation,
} from "../../redux/features/user/userApi";
import { useUpdateUserRoleMutation } from "../../redux/features/auth/authApi";

const UsersSection = () => {
  const {
    data: usersData,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetAllUsersAdminQuery();
  const [updateUserRole] = useUpdateUserRoleMutation();
  const [deleteUser] = useDeleteUserByAdminMutation();
  const [updateUserByAdmin] = useUpdateUserByAdminMutation();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [editedUserData, setEditedUserData] = useState({
    isAccepted: "",
    responsibilities: [],
    role: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const handleEdit = (user) => {
    setUserToEdit(user);
    setEditedUserData({
      isAccepted: user.isAccepted,
      responsibilities: Array.isArray(user.responsibilities)
        ? user.responsibilities
        : user.responsibilities
        ? user.responsibilities.split(", ")
        : [],
      role: user.role,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (user) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteUser(userToDelete._id).unwrap();
      refetch();
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleAccept = async (id) => {
    try {
      await updateUserRole({ email: id, role: "user" }).unwrap();
      refetch();
    } catch (error) {
      console.error("Error accepting user:", error);
    }
  };

  const handleUpdateUser = async () => {
    try {
      setIsUpdating(true);

      if (userToEdit.role !== editedUserData.role) {
        await updateUserRole({
          id: userToEdit._id,
          role: editedUserData.role,
        }).unwrap();
      }

      await updateUserByAdmin({
        id: userToEdit._id,
        userData: {
          isAccepted: editedUserData.isAccepted,
          responsibilities: editedUserData.responsibilities,
        },
      }).unwrap();

      refetch();
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  if (isError) {
    return <div>{error.data.message} </div>;
  }

  const userResponsibilities = [
    "Senior Pastor",
    "Assistant Pastor",
    "Administration leaders",
    "Worship Team Director",
    "Administration leader",
    "Media Team",
    "Senior Elder",
    "Finance Management",
    "Hospitality",
    "Event planner",
    "Choir Director",
    "Music Director",
    "Instrumentalist",
    "Worship Team",
    "Driver",
    "Finance",
    "Media",
  ];

  return (
    <Box className="m-8 max-w-7xl mx-auto px-4">
      <Typography variant="h4" component="h2" className="font-bold mb-6">
        Users
      </Typography>
      {isLoading ? (
        <Box className="flex justify-center items-center h-64">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} className="shadow-md">
          <Table>
            <TableHead>
              <TableRow className="bg-gray-100">
                <TableCell>Avatar</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Verified</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Responsibilities</TableCell>
                <TableCell>Acceptance Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData?.users.map((user) => (
                <TableRow key={user._id} className="hover:bg-gray-50">
                  <TableCell>
                    <Avatar src={user.avatar.url} alt={user.fullname} />
                  </TableCell>
                  <TableCell>{user.fullname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.isVerified ? "Verified" : "Not Verified"}
                      color={user.isVerified ? "success" : "error"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    {Array.isArray(user.responsibilities)
                      ? user.responsibilities.join(", ")
                      : user.responsibilities || "N/A"}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={user.isAccepted}
                      color={
                        user.isAccepted === "Pending" ? "warning" : "success"
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleEdit(user)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(user)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Delete />
                    </IconButton>
                    {user.role === "user" && (
                      <IconButton
                        size="small"
                        onClick={() => handleAccept(user._id)}
                        className="text-green-500 hover:text-green-700"
                      >
                        <CheckCircle />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              value={editedUserData.role}
              onChange={(e) =>
                setEditedUserData({
                  ...editedUserData,
                  role: e.target.value,
                })
              }
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="acceptance-status-label">
              Acceptance Status
            </InputLabel>
            <Select
              labelId="acceptance-status-label"
              value={editedUserData.isAccepted}
              onChange={(e) =>
                setEditedUserData({
                  ...editedUserData,
                  isAccepted: e.target.value,
                })
              }
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Accepted">Accepted</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="responsibilities-label">
              Responsibilities
            </InputLabel>
            <Select
              labelId="responsibilities-label"
              multiple
              value={editedUserData.responsibilities}
              onChange={(e) =>
                setEditedUserData({
                  ...editedUserData,
                  responsibilities: e.target.value,
                })
              }
              renderValue={(selected) => selected.join(", ")}
            >
              {userResponsibilities.map((responsibility) => (
                <MenuItem key={responsibility} value={responsibility}>
                  {responsibility}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEditDialogOpen(false)}
            disabled={isUpdating}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateUser}
            color="primary"
            disabled={isUpdating}
          >
            {isUpdating ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete user {userToDelete?.fullname}? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            className="text-gray-600"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            color="error"
            autoFocus
            className="bg-red-500 text-white hover:bg-red-600"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UsersSection;
