import React from "react";
import { useGetAllUsersQuery } from "../redux/features/user/userApi";

const placeholderImage = "https://via.placeholder.com/150";

function Officials() {
  const { data: usersData, isLoading, isError } = useGetAllUsersQuery();

  if (isLoading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (isError) {
    return <div className="text-center py-10 text-red-500">Error loading leaders data</div>;
  }

  const leaders = usersData?.users || [];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-white min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-extrabold text-gray-900 text-center mb-12">
          Our Church Leaders
        </h1>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
          {leaders.map((leader) => (
            <div key={leader._id} className="bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
              <div className="relative">
                <img
                  src={leader.avatar?.url || placeholderImage}
                  alt={leader.fullname}
                  className="w-full h-48 sm:h-56 md:h-64 object-cover object-center"
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end">
                  <h3 className="text-lg md:text-xl font-semibold text-white p-3 md:p-4 w-full bg-black bg-opacity-60">
                    {leader.fullname}
                  </h3>
                </div>
              </div>
              <div className="p-3 md:p-4">
                <div className="flex flex-wrap gap-2">
                  {Array.isArray(leader.responsibilities) ? (
                    leader.responsibilities.map((role, index) => (
                      <span key={index} className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded-full">
                        {role}
                      </span>
                    ))
                  ) : (
                    <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded-full">
                      {leader.responsibilities || "No Role"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Officials;