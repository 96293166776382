import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Hero from "./Pages/Hero/Hero";
import CoreBeliefs from "./Pages/CoreBeliefs";
import Location from "./Pages/Location";
import Activities from "./Pages/Activities";
import Gallery from "./components/Gallery";
import YearlyActivities from "./Pages/YearlyActivities";

import Officials from "./Pages/Officials";
import Registration from "./components/Registration";
import ActivateAccount from "./components/ActivateAccount";
import UserDashboard from "./components/UserDashboard/UserDashboard";
import ChangeAvatar from "./components/UserDashboard/ChangeAvatar";
import ChangePassword from "./components/UserDashboard/ChangePassword";
import UserProfile from "./components/UserDashboard/UserProfile";
import PrivateRoute from "./hooks/PrivateRoute";
import TokenRefresher from "./hooks/TokenRefresher";
import useTokenExpiration from "./hooks/useTokenExpiration";
import { useLoadUserQuery } from "./redux/features/api/apiSlice";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NotFoundPage from "./components/NotFoundPage";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import { Toaster } from "react-hot-toast";
import RegistrationConfirmation from "./components/RegistrationConfirmation";
import AdminRoute from "./hooks/AdminRoute";
import Loader from "./components/Loader";
import LoginPage from  './Pages/LoginPage'


function App() {
  const { isLoading: isUserLoading } = useLoadUserQuery();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const theme = createTheme();
  useTokenExpiration();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAppLoading(false);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);

  if (isUserLoading || isAppLoading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <Router>
        <div className="App">
          <TokenRefresher />
          <Routes>
            <Route exact path="/" element={<Hero />} />
            <Route path="/beliefs" element={<CoreBeliefs />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/location" element={<Location />} />
            <Route path="/yearly-activities" element={<YearlyActivities />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/officials" element={<Officials />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/activate/:token" element={<ActivateAccount />} />
            <Route
              path="/registration-confirmation"
              element={<RegistrationConfirmation />}
            />

            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<UserDashboard />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/change-avatar" element={<ChangeAvatar />} />
            </Route>

            <Route element={<AdminRoute />}>
              <Route path="/admin/*" element={<AdminDashboard />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
         
        </div>
      </Router>
    </ThemeProvider>
  );
}
export default App