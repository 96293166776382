import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetLayoutQuery,
  useAddYoutubeActivityMutation,
  useUpdateYoutubeActivityMutation,
  useDeleteYoutubeActivityMutation,
} from "../../redux/features/Admin/layouts/layoutApi";
import YouTubeIcon from "@mui/icons-material/YouTube";

const ChurchActivitiesSection = () => {
  const { data: layout, isLoading, refetch } = useGetLayoutQuery();
  const [addYoutubeActivity] = useAddYoutubeActivityMutation();
  const [updateYoutubeActivity] = useUpdateYoutubeActivityMutation();
  const [deleteYoutubeActivity] = useDeleteYoutubeActivityMutation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    if (layout?.layout?.youtubeActivities) {
      const formattedActivities = layout.layout.youtubeActivities.map(
        (activity) => ({
          id: activity._id,
          englishTitle: activity.title?.englishTitle || "",
          koreanTitle: activity.title?.koreanTitle || "",
          youtubeLink: activity.youtubeLink || "",
          date: activity.date || "",
        })
      );
      setActivities(formattedActivities);
    }
  }, [layout]);

  const handleAdd = () => {
    setCurrentActivity(null);
    setSelectedDate(null);
    setDialogOpen(true);
  };

  const handleEdit = (activity) => {
    setCurrentActivity(activity);
    setSelectedDate(activity.date ? dayjs(activity.date) : null);
    setDialogOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this activity?")) {
      try {
        await deleteYoutubeActivity(id).unwrap();
        refetch();
      } catch (error) {
        console.error("Error deleting activity:", error);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const activityData = Object.fromEntries(formData.entries());

    activityData.date = selectedDate ? selectedDate.toISOString() : null;

    const apiData = {
      title: {
        englishTitle: activityData.englishTitle || "",
        koreanTitle: activityData.koreanTitle || "",
      },
      youtubeLink: activityData.youtubeLink || "",
      date: activityData.date,
    };

    try {
      if (currentActivity) {
        await updateYoutubeActivity({
          activityId: currentActivity.id,
          ...apiData,
        }).unwrap();
      } else {
        await addYoutubeActivity(apiData).unwrap();
      }
      setDialogOpen(false);
      refetch();
    } catch (error) {
      console.error("Error submitting activity:", error);
    }
  };

  const columns = [
    { field: "englishTitle", headerName: "English Title", width: 200 },
    { field: "koreanTitle", headerName: "Korean Title", width: 200 },
    {
      field: "youtubeLink",
      headerName: "YouTube",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<YouTubeIcon />}
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </Button>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueGetter: (params) => {
       if (params) {
          const formattedDate = dayjs(params).format('MMMM D, YYYY')
          return formattedDate;
        }
        return "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="flex space-x-2">
          <EditIcon
            className="text-blue-500 cursor-pointer"
            onClick={() => handleEdit(params.row)}
          />
          <DeleteIcon
            className="text-red-500 cursor-pointer"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          Church Activities
        </h2>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          className="bg-blue-500 hover:bg-blue-600"
        >
          Add Activity
        </Button>
      </div>
      <div style={{ height: 400, width: "100%" }} className="bg-gray-50">
        {activities.length > 0 ? (
          <DataGrid
            rows={activities}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        ) : (
          <p>No activities found.</p>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {currentActivity ? "Edit Activity" : "Add New Activity"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="englishTitle"
              label="English Title"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={currentActivity?.englishTitle || ""}
              className="mb-4"
            />
            <TextField
              margin="dense"
              name="koreanTitle"
              label="Korean Title"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={currentActivity?.koreanTitle || ""}
              className="mb-4"
            />
            <TextField
              margin="dense"
              name="youtubeLink"
              label="YouTube Link"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={currentActivity?.youtubeLink || ""}
              className="mb-4"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth className="mb-4" />
                )}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              {currentActivity ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ChurchActivitiesSection;