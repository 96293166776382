import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useGetDashboardAnalyticsQuery } from "../../redux/features/Admin/Analytics/analyticsApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const DashboardAnalytics = () => {
  const {
    data: analyticsData,
    isLoading,
    isError,
  } = useGetDashboardAnalyticsQuery();

  if (isLoading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (isError) {
    return <div className="text-center py-4 text-red-500">Error loading analytics data</div>;
  }

  const chartData = {
    labels: analyticsData.data.monthlySignups.labels,
    datasets: [
      {
        label: "User Signups",
        data: analyticsData.data.monthlySignups.data,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="p-4 font-sans">
      <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-4 md:mb-6">
        Dashboard Analytics
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 mb-6 md:mb-8">
        {Object.entries(analyticsData.data).map(([key, value]) => {
          if (key !== "monthlySignups") {
            return (
              <div key={key} className="bg-white p-3 md:p-4 rounded-lg shadow-md">
                <h3 className="text-sm md:text-lg font-semibold text-gray-700 mb-1 md:mb-2">
                  {key.replace(/([A-Z])/g, " $1").trim()}
                </h3>
                <p className="text-xl md:text-3xl font-bold text-blue-600">{value}</p>
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
        <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-3 md:mb-4">
          User Signups Over Time
        </h3>
        <div className="h-64 md:h-96">
          <Line 
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardAnalytics;