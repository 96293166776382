import React, { useState, useEffect } from "react";
import {
  useUpdateHeroSectionMutation,
  useGetLayoutQuery,
} from "../../redux/features/Admin/layouts/layoutApi";
import toast from "react-hot-toast";

function UpdateHeroSection() {
  const [updateHeroSection] = useUpdateHeroSectionMutation();
  const { data: layoutData, isLoading, isError } = useGetLayoutQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({
    welcomeTitle: "",
    welcomeText: {
      english: "",
      korean: "",
    },
    heroImage: null,
  });

  useEffect(() => {
    if (layoutData && layoutData.layout && layoutData.layout.heroSection) {
      setFormData({
        welcomeTitle: layoutData.layout.heroSection.welcomeTitle,
        welcomeText: {
          english: layoutData.layout.heroSection.welcomeText.english,
          korean: layoutData.layout.heroSection.welcomeText.korean,
        },
        heroImage: null,
      });
    }
  }, [layoutData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "english" || name === "korean") {
      setFormData((prev) => ({
        ...prev,
        welcomeText: {
          ...prev.welcomeText,
          [name]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: files ? files[0] : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    const formDataToSend = new FormData();
    formDataToSend.append("welcomeTitle", formData.welcomeTitle);
    formDataToSend.append("welcomeText[english]", formData.welcomeText.english);
    formDataToSend.append("welcomeText[korean]", formData.welcomeText.korean);
    if (formData.heroImage) {
      formDataToSend.append("heroImage", formData.heroImage);
    }

    try {
      await updateHeroSection(formDataToSend).unwrap();
      toast.success("Hero section updated successfully");
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Failed to update hero section");
    } finally {
      setIsUpdating(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading hero section</div>;
  if (!layoutData || !layoutData.layout || !layoutData.layout.heroSection)
    return <div>No hero section data available</div>;

  const { heroSection } = layoutData.layout;

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      {/* Current Hero Section display */}
      <h2 className="text-2xl font-bold mb-6 text-gray-800">
        Current Hero Section
      </h2>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">Welcome Title</h3>
        <p>{heroSection.welcomeTitle}</p>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">English Welcome Text</h3>
        <p>{heroSection.welcomeText.english}</p>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">Korean Welcome Text</h3>
        <p>{heroSection.welcomeText.korean}</p>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">Hero Image</h3>
        <img src={heroSection.heroImage?.url} alt="Hero" className="max-w-md" />
      </div>
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out"
      >
        Update Hero Section
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg font-bold mb-4">Update Hero Section</h3>
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="space-y-4"
            >
              {/* Form fields */}
              <div>
                <label
                  htmlFor="welcomeTitle"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Welcome Title
                </label>
                <input
                  type="text"
                  id="welcomeTitle"
                  name="welcomeTitle"
                  value={formData.welcomeTitle}
                  onChange={handleChange}
                  placeholder="Enter Welcome Title"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="english"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  English Welcome Text
                </label>
                <textarea
                  id="english"
                  name="english"
                  value={formData.welcomeText.english}
                  onChange={handleChange}
                  placeholder="Enter English Welcome Text"
                  rows="3"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="korean"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Korean Welcome Text
                </label>
                <textarea
                  id="korean"
                  name="korean"
                  value={formData.welcomeText.korean}
                  onChange={handleChange}
                  placeholder="Enter Korean Welcome Text"
                  rows="3"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="heroImage"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Hero Image
                </label>
                <input
                  type="file"
                  id="heroImage"
                  name="heroImage"
                  onChange={handleChange}
                  accept="image/*"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                  disabled={isUpdating}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-blue-300"
                  disabled={isUpdating}
                >
                  {isUpdating ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdateHeroSection;
