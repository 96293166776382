import React from "react";
import { useGetLayoutQuery } from "../../redux/features/Admin/layouts/layoutApi";

function About() {
  const { data, isLoading, isError } = useGetLayoutQuery();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center h-screen">
        Error loading content
      </div>
    );
  }

  const heroSection = data?.layout?.heroSection || {};

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 sm:py-16 lg:py-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            {heroSection.heroImage && (
              <img
                src={heroSection.heroImage.url}
                alt="Hero Image"
                className="rounded-lg shadow-xl w-full h-auto"
              />
            )}
          </div>
          <div className="lg:w-1/2 lg:pl-16">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900 mb-6 sm:mb-8 leading-tight">
              {heroSection.welcomeTitle || "Welcome"}
            </h1>
            <div className="space-y-4 sm:space-y-6">
              {heroSection.welcomeText && (
                <>
                  <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
                    {heroSection.welcomeText.english}
                  </p>
                  <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
                    {heroSection.welcomeText.korean}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
