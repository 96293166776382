// hooks/useTokenExpiration.js
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userLoggedOut } from "../redux/features/auth/authSlice";
import { useRefreshTokenMutation } from "../redux/features/api/apiSlice";

const useTokenExpiration = () => {
  const dispatch = useDispatch();
  const { token, tokenExpiration } = useSelector((state) => state.auth);
  const [refreshToken] = useRefreshTokenMutation();

  useEffect(() => {
    if (!token || !tokenExpiration) return;

    const checkExpiration = setInterval(() => {
      if (Date.now() >= tokenExpiration) {
        refreshToken()
          .unwrap()
          .catch(() => {
            dispatch(userLoggedOut());
          });
      }
    }, 1000); // Check every second

    return () => clearInterval(checkExpiration);
  }, [token, tokenExpiration, dispatch, refreshToken]);
};

export default useTokenExpiration;
