import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardAnalytics from './DashboardAnalytics';
import UsersSection from './UsersSection';
import ChurchActivitiesSection from './ChurchActivitiesSection';
import YearlyActivitiesSection from './YearlyActivitiesSection';
import GallerySection from './GallerySection';
import CustomizationSection from './CustomizationSection';
import Logout from './Logout';
import { MenuOutlined } from '@mui/icons-material';

const AdminDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <button
        className="md:hidden fixed top-4 left-4 z-20 bg-blue-500 text-white p-2 rounded"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <MenuOutlined />
      </button>
      <div className={`md:block ${sidebarOpen ? 'block' : 'hidden'}`}>
        <Sidebar closeSidebar={() => setSidebarOpen(false)} />
      </div>
      <div className="flex-1 overflow-x-hidden overflow-y-auto">
        <main className="p-4 md:p-6">
          <Routes>
            <Route path="/dashboard" element={<DashboardAnalytics />} />
            <Route path="/users" element={<UsersSection />} />
            <Route path="/church-activities" element={<ChurchActivitiesSection />} />
            <Route path="/yearly-activities" element={<YearlyActivitiesSection />} />
            <Route path="/gallery" element={<GallerySection />} />
            <Route path="/customization" element={<CustomizationSection />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;