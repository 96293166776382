import React from "react";
import { useGetLayoutQuery } from "../redux/features/Admin/layouts/layoutApi";

function Gallery() {
  const { data: layoutData, isLoading, isError } = useGetLayoutQuery();

  if (isLoading) return <div className="text-center py-10">Loading...</div>;
  if (isError)
    return (
      <div className="text-center py-10 text-red-500">
        Error loading gallery
      </div>
    );

  const images = layoutData?.layout?.gallery?.images || [];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-12">
        Gallery
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6">
        {images.map((image, index) => (
          <div key={image._id} className="overflow-hidden rounded-lg shadow-lg">
            <img
              src={image.url}
              alt={`Church Image ${index + 1}`}
              className="w-full h-48 sm:h-56 md:h-64 object-cover object-center transition duration-300 ease-in-out transform hover:scale-105"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
