import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useLoadUserQuery } from "../redux/features/api/apiSlice";

const PrivateRoute = () => {
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useLoadUserQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
