import { apiSlice } from "../api/apiSlice";
import { userLoggedIn, userLoggedOut, userRegistration, userActivated } from "./authSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: "users",
        method: "POST",
        body: data,
        credentials: "include",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          dispatch(userRegistration({ token: result.data.activationToken }));
        } catch (error) {
          console.log(error.message);
        }
      },
    }),
    activateUser: builder.mutation({
      query: (token) => ({
        url: `auth/activate/${token}`,
        method: "GET",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          dispatch(userActivated());
        } catch (error) {
          console.log(error.message);
        }
      },
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/sign-in",
        method: "POST",
        body: credentials,
        credentials: "include",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            userLoggedIn({
              accessToken: result.data.accessToken,
              user: result.data.user,
            })
          );
        } catch (error) {
          console.error('Login mutation error:', error.message);
        }
      },
    }),
    logOut: builder.mutation({
      query: () => ({
        url: "auth/logout",
        method: "GET",
        credentials: "include",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          dispatch(userLoggedOut());
        } catch (error) {
          console.log(error.message);
        }
      },
    }),
    changePassword: builder.mutation({
      query: ({ oldPassword, newPassword }) => ({
        url: "users/change-password",
        method: "PUT",
        body: { oldPassword, newPassword },
        credentials: "include",
      }),
    }),
    updateUserRole: builder.mutation({
      query: ({ id, role }) => ({
        url: `/auth/admin/change-role`,
        method: 'PUT',
        body: { id, role },
      }),
    }),

  }),
});

export const { useRegisterMutation, useActivateUserMutation, useLoginMutation, useLogOutMutation,useChangePasswordMutation,useUpdateUserRoleMutation } = authApi;
