import { apiSlice } from "../api/apiSlice";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateAvatar: builder.mutation({
      query: (formData) => ({
        url: "users/me/avatar",
        method: "PUT",
        body: formData,
        credentials: "include",
        prepareHeaders: (headers) => {
          headers.set("Content-Type", "multipart/form-data");
          return headers;
        },
      }),
    }),
    editProfile: builder.mutation({
      query: ({ name }) => ({
        url: "users/update-info",
        method: "PUT",
        body: { name },
        credentials: "include",
      }),
    }),
    updatePassword: builder.mutation({
      query: ({ oldPassword, newPassword }) => ({
        url: "users/update-password",
        method: "PUT",
        body: { oldPassword, newPassword },
        credentials: "include",
      }),
    }),
    getAllUsers: builder.query({
      query: () => ({
        url: "users/public",
        method: "GET",
        credentials: "include",
      }),
    }),
    getAllUsersAdmin: builder.query({
      query: () => ({
        url: "users",
        method: "GET",
        credentials: "include",
      }),
    }),
    updateUserRole: builder.mutation({
      query: ({ email, role }) => ({
        url: "users/role",
        method: "PUT",
        body: { email, role },
        credentials: "include",
      }),
    }),
    deleteUserByAdmin: builder.mutation({
      query: (id) => ({
        url: `users/admin/delete-user/${id}`,
        method: "DELETE",
        credentials: "include",
      }),
    }),


    updateUserByAdmin: builder.mutation({
      query: ({ id, userData }) => ({
        url: `users/admin/update-user/${id}`,
        method: 'PUT',
        body: userData,
        credentials: 'include',
      }),
    }),
  }),
});

export const {
  useUpdateAvatarMutation,
  useUpdatePasswordMutation,
  useEditProfileMutation,
  useGetAllUsersQuery,
  useUpdateUserRoleMutation,
  useDeleteUserByAdminMutation,
  useGetAllUsersAdminQuery,
  useUpdateUserByAdminMutation
} = userApi;
