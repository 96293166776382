import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetLayoutQuery,
  useAddYearlyActivityMutation,
  useUpdateYearlyActivityMutation,
  useDeleteYearlyActivityMutation,
} from "../../redux/features/Admin/layouts/layoutApi";

const ChurchActivitiesSection = () => {
  const { data: layout, isLoading, refetch } = useGetLayoutQuery();
  const [addYearlyActivity] = useAddYearlyActivityMutation();
  const [updateYearlyActivity] = useUpdateYearlyActivityMutation();
  const [deleteYearlyActivity] = useDeleteYearlyActivityMutation();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [yearlyActivities, setYearlyActivities] = useState([]);
  const [activityToDelete, setActivityToDelete] = useState(null);

  useEffect(() => {
    if (layout?.layout?.yearlyActivities) {
      const formattedYearlyActivities = layout.layout.yearlyActivities.map(
        (activity) => ({
          id: activity._id,
          month: activity.month,
          eventTitle: activity.eventTitle,
          content: activity.content,
        })
      );
      setYearlyActivities(formattedYearlyActivities);
    }
  }, [layout]);

  const handleAdd = () => {
    setCurrentActivity(null);
    setDialogOpen(true);
  };

  const handleEdit = (activity) => {
    setCurrentActivity(activity);
    setDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteYearlyActivity(activityToDelete.id).unwrap();
      refetch();
    } catch (error) {
      console.error("Error deleting activity:", error);
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const openDeleteDialog = (activity) => {
    setActivityToDelete(activity);
    setDeleteDialogOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const activityData = Object.fromEntries(formData.entries());

    const apiData = {
      month: activityData.month || "",
      eventTitle: activityData.eventTitle || "",
      content: activityData.content || "",
    };

    try {
      if (currentActivity) {
        await updateYearlyActivity({
          activityId: currentActivity.id,
          ...apiData,
        }).unwrap();
      } else {
        await addYearlyActivity(apiData).unwrap();
      }
      setDialogOpen(false);
      refetch();
    } catch (error) {
      console.error("Error submitting activity:", error);
    }
  };

  const yearlyColumns = [
    { field: "month", headerName: "Month", width: 150 },
    { field: "eventTitle", headerName: "Event Title", width: 200 },
    { field: "content", headerName: "Content", width: 400 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="flex space-x-2">
          <EditIcon
            className="text-blue-500 cursor-pointer"
            onClick={() => handleEdit(params.row)}
          />
          <DeleteIcon
            className="text-red-500 cursor-pointer"
            onClick={() => openDeleteDialog(params.row)}
          />
        </div>
      ),
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          Yearly Activities
        </h2>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          className="bg-blue-500 hover:bg-blue-600"
        >
          Add Activity
        </Button>
      </div>
      <div style={{ height: 400, width: "100%" }} className="bg-gray-50">
        {yearlyActivities.length > 0 ? (
          <DataGrid
            rows={yearlyActivities}
            columns={yearlyColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        ) : (
          <p>No yearly activities found.</p>
        )}
      </div>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {currentActivity ? "Edit Activity" : "Add New Activity"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <FormControl fullWidth variant="outlined" className="mb-4">
              <InputLabel>Month</InputLabel>
              <Select
                name="month"
                label="Month"
                defaultValue={currentActivity?.month || ""}
              >
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              name="eventTitle"
              label="Event Title"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={currentActivity?.eventTitle || ""}
              className="mb-4"
            />
            <TextField
              margin="dense"
              name="content"
              label="Content"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={currentActivity?.content || ""}
              className="mb-4"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              {currentActivity ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this activity?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} variant="contained" color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChurchActivitiesSection;
