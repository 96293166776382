// src/components/RegistrationConfirmation.js
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const RegistrationConfirmation = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
          className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-green-100"
        >
          <motion.svg
            className="h-16 w-16 text-green-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </motion.svg>
        </motion.div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Registration Successful!</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          An activation link has been sent to your email. Please check your inbox and follow the instructions to activate your account.
        </p>
        <p className="mt-2 text-center text-sm text-gray-600">
          Note: After activation, you will not be able to log in until a pastor approves and accepts your status.
        </p>
        <div className="mt-6 flex flex-col space-y-4">
          <Link
            to="/login"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Go to Login Page
          </Link>
          <Link
            to="/"
            className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Return to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegistrationConfirmation;